<template>
    <!-- 政治法规（详情） -->
    <div class="PoliticalDeatil">
        <div class="banner">
          <img v-if="queryId==595" src="../assets/images/policy/banner.png" class="bimg" alt="" srcset="" width="100%">
          <img v-if="queryId==596" src="../assets/images/firm/banner.png" class="bimg" alt="" srcset="" width="100%"> 
        </div>
        <div class="orientation">
            <div class="orientation-content">
                您当前的位置：<a href="/">首页</a> > <a href="/" v-if="queryId==595">政策法规</a><a href="/" v-if="queryId==596">企业风采</a>
            </div>
        </div>
        <div class="detailed-introduction">
            <div class="introduction">
                <p class="introduction-title" v-if="queryId==595">{{zzfg.name}}</p>
				 <p class="introduction-title" v-if="queryId==596">{{zzfg.gsmc}}</p>
                <p class="introduction-time">{{zzfg.time}}</p>
             <div class="introduction-img">
                    <img  :src="zzfg.img" class="int-img" alt="">
                </div>
                <p class="introduction-description">
                      {{zzfg.description}}
                </p>

            </div>
        </div>

 
    </div>
  </template>
  
  <script>

	  import request from "../utils/request.js";
	  import { apiUrl ,fileUrl} from '@/config'
  import Swiper from "swiper";
  import { setTimeout } from "timers";
  export default {
    data() {
      return {
zzfg:{
	name:'',
	time:'',
	description:'',
	img:''
},
 imgurl:fileUrl,
queryId:this.$route.params.queryId,     
dataId:this.$route.params.dataId, 
      };
    },
    watch: {},
    methods: {
     /**政治法规详情**/
     getZzfgxq(){
     	var that=this
     	var queryId=that.queryId
     	var dataId=that.dataId
     	request.get('/formMake/view/'+queryId+'/'+dataId).then(res =>{
     		if(res.code==0){
     			const {data}=res.data
				that.zzfg=data.editMData
     			that.zzfg.name=data.editMData.bt
     			that.zzfg.time=data.editMData.cjsj
     			that.zzfg.description=data.editMData.nr
     			if (data && data.editMData && data.editMData.tp && data.editMData.tp.length > 0) {  
     			    // 获取第一个图片的URL  
     			    var imageUrl = data.editMData.tp[0].url;  
     			    //  console.log(imageUrl)
     			    // 将URL赋值给that.zzfg.img  
     			    that.zzfg.img = this.imgurl+imageUrl;  
					 console.log( that.zzfg.img)
     			} else {  
     			    console.error("No image URL found in the data.");  
     			}
     			
     		}
     	})
     
     
     }
  
    },
    mounted() {
		this.getZzfgxq()
		console.log(this.queryId,this.dataId,'dataid')
	},
  };
  </script>
  <style lang="scss" scoped>
  .sbtitle{
    margin-top:60px !important;
  }
  .PoliticalDeatil {
    .banner {
      width: 100%;
      height:300px;
      .bimg{
        width: 100%;
        height:100%;
      }
  
    }
    .orientation{
        background-color: #f5f5f5;
        height: 60px;   
        .orientation-content{
            width: 1200px;
            height: 100%;
            line-height: 60px;
            margin: 0 auto;
            color:#666666
        }
    }
    .detailed-introduction{
        margin: 80px 0;
        .introduction{
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            text-align: center;
            .introduction-title{
                font-size: 30px;
                font-weight: bold;
            }
            .introduction-time{
                font-size: 20px;
                line-height: 30px;
                font-weight: bold;
            }
            .introduction-img{
                display: flex;
                justify-content: center;
                align-items: center;
                // height: 550px;
                background-size: cover;
                margin-top: 30px;
                .int-img{
                    width: 900px;
                    height: 550px;
                }
            }
            .introduction-description{
                font-size: 18px;
                color: #666666;
                font-weight: bold;
                text-indent: 2rem;
                text-align: left;
                margin-top: 20px;
            }
        }
    }
  

  
  
  }
  
  </style>